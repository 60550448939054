import {saveAs} from "file-saver"
import ExcelJS from "exceljs";

export async function jsonQuery(query, payload={}, hasToAlert=true) {

    let headers = {}
    if(payload.constructor.name === 'ActionPayload') {
        headers = payload.pageHeaders
        payload = payload.payload
    }

    headers['Content-Type'] = 'application/json'

    /*let token = null
    if(store.rootState !== undefined) token = store.rootState.token
    else token = store.state.token
    if(token !== null) headers['Authorization'] = token*/

    return fetch(process.env.VUE_APP_API, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({
                query: query,
                variables: payload
            }
        )
    }).then((res) => {
        return res.json()
    }).then((res) => {
        if('errors' in res) Promise.reject(res.errors[0].message).catch(r => {if(hasToAlert) alert(r)})
        return res
    })
}

export function nbDaysFromDate(date) {
    return Math.floor(date.getTime() / 86400000)
}

export function nbDaysFromStrDate(strDate) {
    return nbDaysFromDate(dateFromStrDate(strDate))
}

export function dateFromNbDays(nbDays) {
    let date = new Date(nbDays * 86400000)
    return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 12, 0, 0)
}

export function dateFromStrDate(strDate) {
    return new Date(strDate + "T12:00:00")
}

export function strDateFromNbDays(nbDays, naWhen0 = false) {
    if(nbDays === 0 && naWhen0) return 'n/a'
    else if(nbDays === 0 && !naWhen0) return ''
    let date = dateFromNbDays(nbDays)
    return strDateFromDate(date)
}

export function strDateFromDate(date, nothingIfEmpty=false) {
    if(date.getTime() <= 50000000 && nothingIfEmpty) return ''
    return date.getFullYear() + '-' + ((date.getMonth() + 1) + '').padStart(2, '0') + '-' + (date.getDate() + '').padStart(2, '0')
}

export function decimals2(floatNumber) {
    let ret = +(Math.round(floatNumber + "e+" + 2)  + "e-" + 2)
    return ret.toFixed(2)
}

export function thousandSeparator(floatNumber) {
    return floatNumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")
}

export function monetary(floatNumber) {
    return thousandSeparator(decimals2(floatNumber)) + " €"
}

export function thousandDecimals(floaNumber) {
    return thousandSeparator(decimals2(floaNumber))
}

export function monthLetter(monthNum, numLetters = 1) {
    let ret = ''
    monthNum = monthNum + ''
    switch (monthNum) {
        case '0':
            ret = 'JANUARY'
            break
        case '1':
            ret = 'FEBRUARY'
            break
        case '2':
            ret = 'MARCH'
            break
        case '3':
            ret = 'APRIL'
            break
        case '4':
            ret = 'MAY'
            break
        case '5':
            ret = 'JUNE'
            break
        case '6':
            ret = 'JULY'
            break
        case '7':
            ret = 'AUGUST'
            break
        case '8':
            ret = 'SEPTEMBER'
            break
        case '9':
            ret = 'OCTOBER'
            break
        case '10':
            ret = 'NOVEMBER'
            break
        case '11':
            ret = 'DECEMBER'
            break
        default:
            return ''
    }
    if(numLetters === -1) return ret
    else return ret.substring(0, numLetters)
}

export function empty(val, exclude0=false) {
    return (val === 0 && !exclude0) || val === null || val === undefined || val === ''
}

export async function download(workbook, name, type="application/octet-stream", extension='.xlsx') {
    const buffer = await workbook.xlsx.writeBuffer()
    saveAs(new Blob([buffer], {type: type}), name + extension)
}

export function getParamValue(param, store) {
    return store.state.parameters.get(param)
}

export function dateFromComplete(complete) {
    complete = "" + complete
    let year = parseInt(complete.substring(0, 4))
    let month = parseInt(complete.substring(4, 6)) - 1
    let day = parseInt(complete.substring(6, 8))
    return new Date(year, month, day, 12, 0, 0)
}

export function completeFromDate(date) {
    let ret = date.getFullYear() + str_pad(parseInt(date.getMonth()) + 1, 2) + str_pad(date.getDate(), 2)
    return ret
}

export function str_pad(str, pad_length, pad_string="0", pad_type="STR_PAD_LEFT"){
    str = "" + str
    let len = pad_length - str.length
    if(len < 0) return str
    let pad = new Array(len + 1).join(pad_string)
    if(pad_type === "STR_PAD_LEFT") return pad + str
    return str + pad
}

export function colorOrder(seller) {
    if(seller.isReadyMinimumFranco) return '#41b222'
    else if(seller.isReadyMinimumOrder) return '#b2a622'
    else return '#B22222'
  }