<template>
<span v-bind:class="[negative ? 'negative' : '']">{{ monetary(props.amount) }}</span>
</template>

<script setup>

import {defineProps, computed} from "vue"
import {monetary} from "../../helpers"

const props = defineProps({
  amount: {
    type: Number,
    default: 0
  }, 
  })
const negative = computed(() => props.amount < 0)
</script>
<style>
.negative {
    color: red;
}
</style>