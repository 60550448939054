<template>
  <crud-form
      table-title="Fournisseurs"
      v-model="sellers"
      :headers="headers"
      :fields="fields"
      :default-values="{
        name: '',
        fctId: '',
        minimumOrder: 0,
        minimumFranco: 0,
        margin: 0.4,
        contact: '',
        phone: '',
        email: '',
        hiboutikId: 0
      }"
      table-name="seller"
      :sort-by="[{key:'name', order:'ASC'}]"
  >
    <template v-slot:additionalActionsBefore="{slotData}">
      <v-btn
          icon="mdi-format-list-bulleted-triangle"
          size="x-small"
          @click.stop="nextOrderContent(slotData)"
          title="Contenu de la prochaine commande"
          class="bg-blue"
      >
      </v-btn>
    </template>
    <template v-slot:additionalActions="{slotData}">
      <v-btn
          icon="mdi-web-refresh"
          size="x-small"
          @click.stop="sourceSeller(slotData)"
          title="Préparer les données depuis le web"
          class="bg-purple"
      >
      </v-btn>
      <v-btn
          icon="mdi-database-arrow-left-outline"
          size="x-small"
          @click.stop="integrateSeller(slotData)"
          title="Intégrer les données préparées"
          class="bg-green"
      >
      </v-btn>
    </template>
    <template v-slot:additionalHeaderButtonsBefore>
      <v-btn
          icon="mdi-cash-sync"
          size="x-small"
          @click.stop="stockUpdateAll"
          title="Synchronisation des stocks"
          class="bg-blue"
      >
      </v-btn>
      &nbsp;
      &nbsp;
      &nbsp;
    </template>
    <template v-slot:[`item.minimals`]="{ item }">
      {{item.minimumOrder}}/{{item.minimumFranco}}
    </template>
    <template v-slot:[`item.margin`]="{ item }">
      {{Math.round(item.margin * 100)}} %
    </template>
    <template v-slot:[`item.order`]="{ item }">
      <v-icon
          size="large"
          :color="colorOrder(item)"
      >
        mdi-truck-check
      </v-icon>
      {{monetary(item.nextOrderTotal)}}
    </template>

    <template v-slot:form="{item, errors}">
      <v-card-text>
        <v-container>
          <v-row>
            <v-col
                cols="12"
                sm="12"
                md="12"
            >
              <v-text-field
                  v-model="item.name"
                  :error-messages="errors.name"
                  label="Nom"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col
                cols="12"
                sm="12"
                md="12"
            >
              <v-text-field
                  v-model="item.fctId"
                  :error-messages="errors.fctId"
                  label="Fct Id (technique)"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col
                cols="12"
                sm="6"
                md="6"
            >
              <v-text-field
                  v-model.number="item.minimumOrder"
                  :error-messages="errors.minimumOrder"
                  label="Commande minimale"
                  type="number"
              ></v-text-field>
            </v-col>
            <v-col
                cols="12"
                sm="6"
                md="6"
            >
              <v-text-field
                  v-model.number="item.minimumFranco"
                  :error-messages="errors.minimumFranco"
                  label="Minimum franco"
                  type="number"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col
                cols="12"
                sm="12"
                md="12"
            >
              <v-text-field
                  v-model.number="item.margin"
                  :error-messages="errors.margin"
                  label="Marge"
                  type="number"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col
                cols="12"
                sm="12"
                md="12"
            >
              <v-text-field
                  v-model="item.contact"
                  :error-messages="errors.contact"
                  label="Contact"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col
                cols="12"
                sm="12"
                md="12"
            >
              <v-text-field
                  v-model="item.phone"
                  :error-messages="errors.phone"
                  label="Téléphone"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col
                cols="12"
                sm="12"
                md="12"
            >
              <v-text-field
                  v-model="item.email"
                  :error-messages="errors.email"
                  label="E-mail"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col
                cols="12"
                sm="12"
                md="12"
            >
              <v-text-field
                  v-model.number="item.hiboutikId"
                  :error-messages="errors.hiboutikId"
                  label="Hiboutik Id"
                  type="number"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </template>

  </crud-form>

  <v-dialog
      v-model="nextOrderDisplay"
      style="z-index:1007;"
      scrollable
  >
    <v-card>
      <v-card-title
          style="position:fixed;z-index:1008;width:100%"
          variant="elevated"
      >
        Liste des jeux à commander pour le fournisseur {{nextOrderData.name}}
      </v-card-title>
        <v-card-text>
          <v-container>

            <crud-form
                table-title="Jeux"
                v-model="nextOrderData.nextOrder"
                :headers="nextOrderHeaders"
                :fields="gameFields"
                :default-values="{
                  name: '',
                  ean13: '',
                  idSeller: 0,
                  idEditor: 0,
                  idCategory: 0,
                  idTax: 0,
                  buyPrice: 0,
                  sellPriceHT: 0,
                  hiboutikId: 0,
                  activated: false,
                  stock: 0,
                  expectedStock: 0
                }"
                :actions="{
                  edit: true,
                  delete: false,
                  new: true
                }"
                table-name="game"
                :items-per-page="100"
            >

              <template v-slot:[`item.seller`]="{ item }">
                {{item.seller.name}}
              </template>
              <template v-slot:[`item.category`]="{ item }">
                {{item.category.name}}
              </template>
              <template v-slot:[`item.stock`]="{ item }">
                {{item.stock}}/{{item.expectedStock}}
              </template>
              <template v-slot:[`item.buyPrice`]="{ item }">
                {{monetary(item.buyPrice)}}
              </template>
              <template v-slot:[`item.activated`]="{ item }">
                <boolean v-model="item.activated"></boolean>
              </template>

              <template v-slot:form="{item, errors}">
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col
                          cols="12"
                          sm="12"
                          md="12"
                      >
                        <v-text-field
                            v-model="item.name"
                            :error-messages="errors.name"
                            label="Nom"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col
                          cols="12"
                          sm="12"
                          md="12"
                      >
                        <v-text-field
                            v-model="item.ean13"
                            :error-messages="errors.ean13"
                            label="Ean13"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col
                          cols="12"
                          sm="6"
                          md="6"
                      >
                        <v-text-field
                            v-model.number="item.stock"
                            :error-messages="errors.stock"
                            label="Stock"
                            type="number"
                        ></v-text-field>
                      </v-col>
                      <v-col
                          cols="12"
                          sm="6"
                          md="6"
                      >
                        <v-text-field
                            v-model.number="item.expectedStock"
                            :error-messages="errors.expectedStock"
                            label="Stock attendu"
                            type="number"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col
                          cols="12"
                          sm="12"
                          md="12"
                      >
                        <v-text-field
                            v-model.number="item.buyPrice"
                            :error-messages="errors.buyPrice"
                            label="Prix d'achat HT"
                            type="number"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col
                          cols="12"
                          sm="12"
                          md="12"
                      >
                        <v-text-field
                            v-model.number="item.sellPriceHT"
                            :error-messages="errors.sellPriceHT"
                            label="Prix de vente HT"
                            type="number"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col
                          cols="12"
                          sm="12"
                          md="12"
                      >
                        <v-text-field
                            v-model.number="item.hiboutikId"
                            :error-messages="errors.hiboutikId"
                            label="Hiboutik Id"
                            type="number"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col
                          cols="12"
                          sm="12"
                          md="12"
                      >
                        <v-switch
                            v-model="item.activated"
                            label="Activé"
                            color="primary"
                            hide-details
                        ></v-switch>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
              </template>
            </crud-form>
          </v-container>
        </v-card-text>
    </v-card>
  </v-dialog>

</template>
<script setup>

import CrudForm from "@/components/elements/CrudForm.vue"
import {ref} from "vue";
import {jsonQuery, monetary, colorOrder} from "@/helpers";
import Boolean from "@/components/elements/Boolean.vue";

const sellers = ref([])
const nextOrderDisplay = ref(false)
const nextOrderData = ref(null)

const fields = ref(`
    id
    name
    fctId
    minimumOrder
    minimumFranco
    margin
    contact
    phone
    email
    hiboutikId
    nextOrderTotal
    isReadyMinimumOrder
    isReadyMinimumFranco
`)

const gameFields = ref(`
    id
    name
    ean13
    idSeller
    seller {
      name
    }
    idEditor
    editor {
      name
    }
    idCategory
    category {
      name
    }
    idTax
    tax {
      amount
    }
    sellerRef
    buyPrice
    sellPriceHT
    hiboutikId
    activated
    stock
    expectedStock
    sellPriceTTC
    toBeOrdered
    qtyToOrder
`)

function refreshSellers() {
  jsonQuery(`
query SellersAll {
  sellersAll {
    ${fields.value}
  }
}
`).then(res => {
    sellers.value = res.data.sellersAll
  })
}
refreshSellers()

function stockUpdateAll() {
  jsonQuery(`
mutation Mutation {
  stockUpdateAll
}
`).then(res => {
    refreshSellers()
  })
}

function nextOrderContent(seller) {
  jsonQuery(`
query Query($sellerByIdId: Int!) {
  sellerById(id: $sellerByIdId) {
    name
    nextOrder {
      ${gameFields.value}
    }
  }
}
`, {sellerByIdId: seller.id}).then(res => {
    nextOrderData.value = res.data.sellerById
    nextOrderDisplay.value = true
  })
}

function sourceSeller(seller) {
  jsonQuery(`
    mutation SourceSeller($idSeller: Int!) {
      sourceSeller(idSeller: $idSeller)
    }
`, {idSeller: seller.id}).then(res => {
  })
}

function integrateSeller(seller) {
  jsonQuery(`
    mutation IntegrateLastSource($idSeller: Int!) {
      integrateLastSource(idSeller: $idSeller)
    }
`, {idSeller: seller.id}).then(res => {
  })
}

const nextOrderHeaders = ref([
  {
    title: 'Nom',
    align: 'start',
    sortable: true,
    key: 'name'
  },
  {
    title: 'EAN13',
    align: 'start',
    sortable: true,
    key: 'ean13'
  },
  {
    title: 'Ref',
    align: 'start',
    sortable: true,
    key: 'sellerRef'
  },
  {
    title: 'Prix',
    align: 'start',
    sortable: true,
    key: 'buyPrice'
  },
  {
    title: 'Qté',
    align: 'start',
    sortable: true,
    key: 'qtyToOrder'
  },
  {
    title: 'Stock',
    align: 'start',
    sortable: true,
    key: 'stock'
  }
])

const headers = ref([
  {
    title: 'Nom',
    align: 'start',
    sortable: true,
    key: 'name'
  },
  {
    title: 'Minima',
    align: 'start',
    sortable: true,
    key: 'minimals'
  },
  {
    title: 'Marge',
    align: 'start',
    sortable: true,
    key: 'margin'
  },
  {
    title: 'Contact',
    align: 'start',
    sortable: true,
    key: 'contact'
  },
  {
    title: 'Tel',
    align: 'start',
    sortable: true,
    key: 'phone'
  },
  {
    title: 'Email',
    align: 'start',
    sortable: true,
    key: 'email'
  },
  {
    title: 'Commande',
    align: 'start',
    sortable: true,
    key: 'order'
  }
])

</script>