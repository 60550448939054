<template>
    <crud-form
        table-title="Clients"
        v-model="customers"
        :headers="headers"
        :fields="fields"
        :default-values="{
            lastName: '',
            firstName: '',
            phone: '',
            email: '',
            comment: ''
        }"
        :actions="{
            edit: false,
            delete: false,
            new: false
        }"
        table-name="customer"
        :items-per-page="100"
        :sortBy="[{ key: 'comment', order: 'desc' }]"
        :force-action-column="true"
    >
    <template v-slot:additionalActionsBefore="{slotData}">
      <v-icon
          size="large"
          class="me-2"
          color="#e60082"
          @click.stop="eraseComment(slotData.id)"
          v-if="!empty(slotData.comment)"
      >
        mdi-eraser-variant
      </v-icon>
    </template>
  </crud-form>
</template>
<script setup>

import CrudForm from "@/components/elements/CrudForm.vue"
import {ref} from "vue"
import {jsonQuery, empty} from "@/helpers";

const headers = ref([
  {
    title: 'Nom',
    align: 'start',
    sortable: true,
    key: 'lastName'
  },
  {
    title: 'Prénom',
    align: 'start',
    sortable: true,
    key: 'firstName'
  },
  {
    title: 'Téléphone',
    align: 'start',
    sortable: true,
    key: 'phone'
  },
  {
    title: 'Email',
    align: 'start',
    sortable: true,
    key: 'email'
  },
  {
    title: 'Commentaire',
    align: 'start',
    sortable: true,
    key: 'comment'
  }
])

const customers = ref([])
const fields = ref(`
    id
    lastName
    firstName
    phone
    email
    comment
`)

function refreshCustomers() {

return jsonQuery(`
    query CustomersAll {
        customersAll {
                ${fields.value}
            }
        }
    `).then(res => {
        customers.value = res.data.customersAll
    })
}
refreshCustomers()

function eraseComment(idCustomer) {
  return jsonQuery(`
    mutation Mutation($eraseCommentId: Int!) {
      eraseComment(id: $eraseCommentId)
    }
    `, {eraseCommentId:idCustomer}).then(res => {
      refreshCustomers()
    })
}

</script>