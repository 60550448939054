<template>

  <v-row>
    <v-col
      cols="12"
      md="2"
      sm="2"
    >
      Valeur stock sélection: <monetary :amount="stockTotal">5</monetary>
    </v-col>
    <v-col
      cols="12"
      md="2"
      sm="2"
    >
    Total à commander: <monetary :amount="toBeOrderedTotal"></monetary>
    </v-col>
    <v-col
      cols="12"
      md="2"
      sm="2"
      v-if="sellerSelected"
    >
    <v-icon
          size="large"
          :color="colorOrder(selectedSeller)"
      >
        mdi-truck-check
      </v-icon> Min/Franco: <monetary :amount="selectedSeller.minimumOrder"></monetary>/<monetary :amount="selectedSeller.minimumFranco"></monetary>
    </v-col>
  </v-row>
  <v-divider></v-divider>
  <crud-form
      table-title="Jeux"
      v-model="filteredGames"
      :headers="headers"
      :fields="fields"
      :default-values="{
        name: '',
        ean13: '',
        sellerRef: '',
        idSeller: 0,
        idEditor: 0,
        idCategory: 0,
        idTax: 0,
        buyPrice: 0,
        sellPriceHT: 0,
        hiboutikId: 0,
        stock: 0,
        expectedStock: 0,
        orderGroupNb: 1
      }"
      :actions="{
        edit: true,
        delete: false,
        new: true
      }"
      table-name="game"
      :items-per-page="100"
      :after-write="afterWriteGame"
  >

    <template v-slot:[`item.seller`]="{ item }">
      {{item.seller.name}}
    </template>
    <template v-slot:[`item.category`]="{ item }">
      {{item.category.name}}
    </template>
    <template v-slot:[`item.stock`]="{ item }">
      {{item.stock}}/{{item.expectedStock}}
    </template>
    <template v-slot:[`item.buyPrice`]="{ item }">
      <monetary :amount="item.buyPrice"></monetary>
    </template>
    <template v-slot:[`item.activated`]="{ item }">
      <boolean v-model="item.activated"></boolean>
    </template>

    <template v-slot:additionalHeaderButtonsBefore>

      <v-row>
        <v-col
          cols="12"
          md="2"
          sm="2"
        >
          <v-checkbox
            v-model="filterActivatedYes"
            color="green"
            label="Dans hiboutik"
            :value="true"
            hide-details
          ></v-checkbox>
        </v-col>
        <v-col
          cols="12"
          md="2"
          sm="2"
        >
          <v-checkbox
            v-model="filterActivatedNo"
            color="red"
            label="Pas dans hiboutik"
            :value="true"
            hide-details
          ></v-checkbox>
        </v-col>
        <v-col
          cols="12"
          md="2"
          sm="2"
        >
          <v-checkbox
            v-model="filterNeeded0"
            color="purple"
            label="Masquer 0 stock attendu"
            :value="true"
            hide-details
          ></v-checkbox>
        </v-col>
        <v-col
          cols="12"
          md="2"
          sm="2"
        >
          <v-checkbox
            v-model="filterToOrder"
            color="orange"
            label="À commander"
            :value="true"
            hide-details
          ></v-checkbox>
        </v-col>
        <v-col
          cols="12"
          md="2"
          sm="2"
        >
          <v-checkbox
            v-model="filterApu"
            color="brown"
            label="Apu"
            :value="true"
            hide-details
          ></v-checkbox>
        </v-col>
        <v-col
          cols="12"
          md="2"
          sm="2"
        >
        {{ filterSeller }}
          <v-select
              v-model="filterSeller"
              :items="sellers"
              item-title="name"
              item-value="id"
              label="Fournisseur"
          >
          <template v-slot:item="{ props, item }">
            <v-list-item v-bind="props">
              <template v-slot:title="{ title }">
                <v-icon
                    v-if="item.raw.id>0"
                    size="large"
                    :color="colorOrder(item.raw)"
                >
                  mdi-truck-check
                </v-icon>
                {{ title }} 
                <span v-if="item.raw.id>0">(<monetary :amount="getSellerOrderTotal(item.raw)"></monetary>)</span>
              </template>
            </v-list-item>
            
          </template>

          </v-select>
        </v-col>
      </v-row>
      <v-text-field
          v-model="gamesFilter"
          label="Filtre"
          prepend-inner-icon="mdi-magnify"
          class="gamesFilter"
      ></v-text-field>
    </template>

    <template v-slot:additionalHeaderButtons>
      <v-btn
          icon="mdi-cash-sync"
          size="x-small"
          @click.stop="stockUpdateAll"
          title="Synchronisation des stocks"
          class="bg-blue"
      >
      </v-btn>
    </template>

    <template v-slot:additionalActionsBefore="{slotData}">
      <v-icon
          v-if="!slotData.activated"
          size="large"
          class="me-2"
          color="#00BFFF"
          @click.stop="sendToHiboutik(slotData)"
      >
        mdi-owl
      </v-icon>

    </template>

    <template v-slot:form="{item, errors}">
      <v-card-text>
        <v-container>
          <v-row>
            <v-col
                cols="12"
                sm="12"
                md="12"
            >
              <v-text-field
                  v-model="item.name"
                  :error-messages="errors.name"
                  label="Nom"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col
                cols="12"
                sm="12"
                md="12"
            >
              <v-text-field
                  v-model="item.ean13"
                  :error-messages="errors.ean13"
                  label="Ean13"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col
                cols="12"
                sm="12"
                md="12"
            >
              <v-text-field
                  v-model="item.sellerRef"
                  :error-messages="errors.sellerRef"
                  label="Seller Ref"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col
                cols="12"
                sm="12"
                md="12"
            >
              <v-select
                  v-model="item.idSeller"
                  :items="sellers"
                  item-title="name"
                  item-value="id"
                  label="Fournisseur"
                  :error-messages="errors.idSeller"
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col
                cols="12"
                sm="12"
                md="12"
            >
              <v-select
                  v-model="item.idCategory"
                  :items="categories"
                  item-title="name"
                  item-value="id"
                  label="Categorie"
                  :error-messages="errors.idCategory"
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col
                cols="12"
                sm="12"
                md="12"
            >
              <v-select
                  v-model="item.idEditor"
                  :items="editors"
                  item-title="name"
                  item-value="id"
                  label="Éditeur"
                  :error-messages="errors.idEditor"
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col
                cols="12"
                sm="12"
                md="12"
            >
              <v-select
                  v-model="item.idTax"
                  :items="taxes"
                  item-title="amount"
                  item-value="id"
                  label="TVA"
                  :error-messages="errors.idTax"
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col
                cols="12"
                sm="4"
                md="4"
            >
              <v-text-field
                  v-model.number="item.stock"
                  :error-messages="errors.stock"
                  label="Stock"
                  type="number"
              ></v-text-field>
            </v-col>
            <v-col
                cols="12"
                sm="4"
                md="4"
            >
              <v-text-field
                  v-model.number="item.expectedStock"
                  :error-messages="errors.expectedStock"
                  label="Stock attendu"
                  type="number"
              ></v-text-field>
            </v-col>
            <v-col
                cols="12"
                sm="4"
                md="4"
            >
              <v-text-field
                  v-model.number="item.orderGroupNb"
                  :error-messages="errors.orderGroupNb"
                  label="Achat par lot de"
                  type="number"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col
                cols="12"
                sm="12"
                md="12"
            >
              <v-text-field
                  v-model.number="item.buyPrice"
                  :error-messages="errors.buyPrice"
                  label="Prix d'achat HT"
                  type="number"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col
                cols="12"
                sm="12"
                md="12"
            >
              <v-text-field
                  v-model.number="item.sellPriceHT"
                  :error-messages="errors.sellPriceHT"
                  label="Prix de vente HT"
                  type="number"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col
                cols="12"
                sm="12"
                md="12"
            >
              <v-text-field
                  v-model.number="item.hiboutikId"
                  :error-messages="errors.hiboutikId"
                  label="Hiboutik Id"
                  type="number"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col
                cols="12"
                sm="12"
                md="12"
            >
              <v-switch
                  v-model="item.activated"
                  label="Activé"
                  color="primary"
                  hide-details
              ></v-switch>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </template>

  </crud-form>
</template>
<script setup>

import CrudForm from "@/components/elements/CrudForm.vue"
import {computed, ref, watch} from "vue";
import {empty, jsonQuery, monetary, colorOrder} from "@/helpers";
import Boolean from "@/components/elements/Boolean.vue";
import Monetary from "@/components/elements/Monetary.vue"

const games = ref([])
const fields = ref(`
    id
    name
    ean13
    sellerRef
    idSeller
    seller {
      name
    }
    idEditor
    editor {
      name
    }
    idCategory
    category {
      name
    }
    idTax
    tax {
      amount
    }
    buyPrice
    sellPriceHT
    hiboutikId
    activated
    stock
    expectedStock
    sellPriceTTC
    toBeOrdered
    qtyToOrder
    orderGroupNb
`)

const categories = ref([])
const editors = ref([])
const sellers = ref([])
const taxes = ref([])
const stockTotal = ref(0)
const toBeOrderedTotal = ref(0)

const filterActivatedYes = ref(true)
const filterActivatedNo = ref(false)
const filterSeller = ref('')
const filterNeeded0 = ref(false)
const filterToOrder = ref(false)
const filterApu = ref(false)

let sellersOrderTotals = new Map()

const selectedSeller = computed(() => {
  if(empty(filterSeller.value)) return null
  for(let seller of sellers.value) {
    if(seller.id === filterSeller.value) return seller
  }
  return null
})

const sellerSelected = computed(() => {
  return !empty(filterSeller.value)
})

jsonQuery(`
query CategorysAll {
  categorysAll {
    id
    name
  }
}
`).then(res => {
  categories.value = res.data.categorysAll
  categories.value.sort()
})

jsonQuery(`
query EditorsAll {
  editorsAll {
    id
    name
  }
}
`).then(res => {
  editors.value = res.data.editorsAll
  editors.value.sort()
})

jsonQuery(`
query SellersAll {
  sellersAll {
    id
    name
    minimumFranco
    minimumOrder
    isReadyMinimumOrder
    isReadyMinimumFranco
  }
}
`).then(res => {
  res.data.sellersAll.push({'id':0, 'name': ''})
  sellers.value = res.data.sellersAll.toSorted((s1, s2) => {
    if(s1.name.toLowerCase() < s2.name.toLowerCase()) return -1
    else if(s1.name.toLowerCase() > s2.name.toLowerCase()) return 1
    else return 0
  })
})

jsonQuery(`
query TaxsAll {
  taxsAll {
    id
    amount
  }
}
`).then(res => {
  taxes.value = res.data.taxsAll
})

let gamesList = new Map()

function refreshGames() {

    return jsonQuery(`
query GamesAll {
  gamesAll {
    ${fields.value}
  }
}
`).then(res => {
      games.value = res.data.gamesAll
      filterGames()
    })
}
refreshGames()

const gamesFilter = ref('')
let filterLastAction = new Date().getTime()
const filteredGames = ref([])
const FILTER_TIME = 1000

function filterGames() {
  
  /*
   * Filter by text
   */
   if(gamesFilter.value.trim().length < 3) filteredGames.value = games.value
  else {
    let time = new Date().getTime()
    if(filterLastAction > time - FILTER_TIME) {
      setTimeout(filterGames, FILTER_TIME - (time - filterLastAction))
    }
    else {
      filterLastAction = new Date().getTime()
      filteredGames.value = games.value.filter(game => {
        return game.name.toLowerCase().includes(gamesFilter.value.toLowerCase()) ||
        game.ean13.trim() === gamesFilter.value ||
        game.seller.name.toLowerCase().includes(gamesFilter.value.trim().toLowerCase()) ||
        game.editor.name.toLowerCase().includes(gamesFilter.value.trim().toLowerCase()) ||
        game.category.name.toLowerCase().includes(gamesFilter.value.trim().toLowerCase())

      })
    }
  }

  /*
   * Filter by activated
   */
  if(filterActivatedNo.value || filterActivatedYes.value) {
    filteredGames.value = filteredGames.value.filter(game => {
        return (!game.activated && filterActivatedNo.value) || (game.activated && filterActivatedYes.value)
      })
  }

  /*
   * Filter by seller
   */
  if(!empty(filterSeller.value)) {
    filteredGames.value = filteredGames.value.filter(game => {
        return game.idSeller === filterSeller.value
      })
  }

  /*
   * Filter by Needed0
   */
  if(filterNeeded0.value) {
    filteredGames.value = filteredGames.value.filter(game => {
        return game.expectedStock > 0
      })
  }

  /*
   * Filter by stockComplete
   */
   if(filterToOrder.value) {
    filteredGames.value = filteredGames.value.filter(game => {
        return game.toBeOrdered
      })
  }

  /*
   * Filter by apu
   */
   if(filterApu.value) {
    filteredGames.value = filteredGames.value.filter(game => {
        return game.stock === 0
      })
  }
  /*
   * Total stock
   */
  stockTotal.value = 0
  toBeOrderedTotal.value = 0
  for(let game of filteredGames.value) {
    stockTotal.value += game.buyPrice * game.stock
    let toBeOrderedGame = game.buyPrice * game.qtyToOrder
    toBeOrderedTotal.value += toBeOrderedGame
    if(!sellersOrderTotals.has(game.idSeller)) sellersOrderTotals.set(game.idSeller, 0)
    sellersOrderTotals.set(game.idSeller, sellersOrderTotals.get(game.idSeller) + toBeOrderedGame)
  }
}
filterGames()

watch(filterActivatedNo, (newValue) => {filterGames()})
watch(filterActivatedYes, (newValue) => {filterGames()})
watch(gamesFilter, (newValue) => {filterGames()})
watch(filterSeller, (newValue) => {filterGames()})
watch(filterNeeded0, (newValue) => {filterGames()})
watch(filterToOrder, (newValue) => {filterGames()})
watch(filterApu, (newValue) => {filterGames()})

function getSellerOrderTotal(seller) {
  return sellersOrderTotals.get(seller.id)
}

function sendToHiboutik(game) {
  jsonQuery(`
  mutation Mutation($idGame: Int!) {
    sendGameToHiboutik(idGame: $idGame) {
      ${fields.value}
    }
  }
  `, {idGame: game.id}).then(res => {
    game = res.data.sendGameToHiboutik
    gamesList = new Map()
    refreshGames()
  })
}

const headers = ref([
  {
    title: 'Nom',
    align: 'start',
    sortable: true,
    key: 'name'
  },
  {
    title: 'EAN13',
    align: 'start',
    sortable: true,
    key: 'ean13'
  },
  {
    title: 'Fournisseur',
    align: 'start',
    sortable: true,
    key: 'seller.name'
  },
  {
    title: 'Éditeur',
    align: 'start',
    sortable: true,
    key: 'editor.name'
  },
  {
    title: 'Categorie',
    align: 'start',
    sortable: true,
    key: 'category.name'
  },
  {
    title: 'Prix HT',
    align: 'start',
    sortable: true,
    key: 'buyPrice'
  },
  {
    title: 'H Id',
    align: 'start',
    sortable: true,
    key: 'hiboutikId'
  },
  {
    title: 'Activé',
    align: 'start',
    sortable: true,
    key: 'activated'
  },
  {
    title: 'Stock',
    align: 'start',
    sortable: true,
    key: 'stock'
  },
  {
    title: 'Achat par',
    align: 'start',
    sortable: true,
    key: 'orderGroupNb'
  },
  {
    title: 'À commander',
    align: 'start',
    sortable: true,
    key: 'qtyToOrder'
  }
])

function afterWriteGame(editedItem, itemUpdated) {
  return refreshGames()
}

function stockUpdateAll() {
  jsonQuery(`
    mutation Mutation {
      stockUpdateAll
    }
`).then(res => {
    refreshGames()
  })
}
</script>

<style scoped>
.gamesFilter {
  max-width: 250px;
}
</style>