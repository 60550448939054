import { createApp, h } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'
import { loadFonts } from './plugins/webfontloader'
import { createVuetify } from 'vuetify'

import App from './App.vue'
import Seller from './components/pages/Seller.vue'
import Game from "@/components/pages/Game.vue"
import Editor from "@/components/pages/Editor.vue"
import Category from "@/components/pages/Category.vue"
import Treso from "@/components/pages/Treso.vue";
import Money from "@/components/pages/Money.vue";
import Customers from './components/pages/Customers.vue'

loadFonts().then()

const routes = [
    { path: '/', component: Seller, name: 'Seller' },
    { path: '/games', component: Game, name: 'Game' },
    { path: '/editors', component: Editor, name: 'Editor' },
    { path: '/categories', component: Category, name: 'Category' },
    { path: '/tresos', component: Treso, name: 'Treso' },
    { path: '/money', component: Money, name: 'Money' },
    { path: '/customers', component: Customers, name: 'Customers' }
];

const router = createRouter({
    mode: 'history',
    history: createWebHistory(),
    routes
});

const vuetify = createVuetify({
    components: {
    },
    defaults: {
        VDataTable: {
            fixedHeader: true,
            noDataText: 'Results not found',
        },
    },
})

createApp(
    {
        setup () {
        },
        render() {
            return h(App)
        }
    }
).use(router)
 .use(vuetify)
 .mount('#app')
